import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

const auth = getAuth();

export const sendSignInEmail = async (email) => {
  const actionCodeSettings = {
    url: 'https://www.glmaitools.com', // Your app's URL
    handleCodeInApp: true
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    alert('Sign-in link sent!');
  } catch (error) {
    console.error('Error sending sign-in link', error);
    alert('Failed to send sign-in link.');
  }
};
