// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-section {
    padding: 10px 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    position: relative;
    margin-bottom: 2px;
  }
  
  .collapse-button {
    background-color: transparent;
    color: #636363;
    border: none;
    cursor: pointer;
    display: block; /* Ensures button takes up full width */
    width: -moz-fit-content;
    width: fit-content; /* Ensures button size adjusts to content */
    margin: 0 auto; /* Centers the button */
    border-bottom: 2px solid transparent;
  }
  
  .collapse-button:hover {
    border-bottom: 2px solid #005bbb;
  }
  
  .info-text {
    overflow: hidden;
    transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
    max-height: 0;
    opacity: 0;
  }
  
  .info-text.expanded {
    max-height: 400px; /* Adjust based on content length */
    opacity: 1;
  }
  
  .info-text.collapsed {
    max-height: 0;
    opacity: 0;
  }
  
  .info-text p {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    font-size: 14px;
    color: #333;
  }

  .info-text h3 {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    font-size: 18px;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/InfoSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,6BAA6B;IAC7B,cAAc;IACd,YAAY;IACZ,eAAe;IACf,cAAc,EAAE,uCAAuC;IACvD,uBAAkB;IAAlB,kBAAkB,EAAE,2CAA2C;IAC/D,cAAc,EAAE,uBAAuB;IACvC,oCAAoC;EACtC;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,gBAAgB;IAChB,2DAA2D;IAC3D,aAAa;IACb,UAAU;EACZ;;EAEA;IACE,iBAAiB,EAAE,mCAAmC;IACtD,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,UAAU;EACZ;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb","sourcesContent":[".info-section {\n    padding: 10px 20px;\n    background-color: #f5f5f5;\n    border-bottom: 1px solid #ddd;\n    position: relative;\n    margin-bottom: 2px;\n  }\n  \n  .collapse-button {\n    background-color: transparent;\n    color: #636363;\n    border: none;\n    cursor: pointer;\n    display: block; /* Ensures button takes up full width */\n    width: fit-content; /* Ensures button size adjusts to content */\n    margin: 0 auto; /* Centers the button */\n    border-bottom: 2px solid transparent;\n  }\n  \n  .collapse-button:hover {\n    border-bottom: 2px solid #005bbb;\n  }\n  \n  .info-text {\n    overflow: hidden;\n    transition: max-height 0.1s ease-out, opacity 0.1s ease-out;\n    max-height: 0;\n    opacity: 0;\n  }\n  \n  .info-text.expanded {\n    max-height: 400px; /* Adjust based on content length */\n    opacity: 1;\n  }\n  \n  .info-text.collapsed {\n    max-height: 0;\n    opacity: 0;\n  }\n  \n  .info-text p {\n    margin-left: 20px;\n    margin-right: 20px;\n    text-align: left;\n    font-size: 14px;\n    color: #333;\n  }\n\n  .info-text h3 {\n    margin-left: 20px;\n    margin-right: 20px;\n    text-align: left;\n    font-size: 18px;\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
