import React, { useState } from 'react';
import './Login.css'; // Import the CSS file
import { sendSignInEmail } from './auth'; // Import the function from auth.js

const Login = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    sendSignInEmail(email)
      .then(() => setMessage('Sign-in link sent! Please check your email.'))
      .catch((error) => setMessage('Failed to send sign-in link. Please try again.'));
  };

  return (
    <div className="login-container">
      <h2>All you need is your email to login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit">Send Sign-In Link</button>
      </form>
      <p>
        Don't worry. We won't spam you. As long as you're okay with it, we will send you updates as we continue developing this tool.
      </p>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Login;
