import React, { useEffect, useState } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from 'firebase/auth';
import Chat from './Chat';
import Header from './Header';
import InfoSection from './InfoSection';
import Login from './Login';
import './App.css';

const auth = getAuth();

function App() {
  const [isInfoCollapsed, setIsInfoCollapsed] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const completeSignIn = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        
        // If no email is stored in localStorage, prompt the user for their email
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
          // Store the email in localStorage to avoid prompting again
          if (email) {
            window.localStorage.setItem('emailForSignIn', email);
          }
        }
        
        // If email is available, proceed with sign-in
        if (email) {
          try {
            const result = await signInWithEmailLink(auth, email, window.location.href);
            window.localStorage.removeItem('emailForSignIn');
            setUser(result.user);
            
            // If the sign-in process was opened in a new tab, close it and refresh the opener window
            if (window.opener) {
              window.opener.location.reload();
              window.close();
            }
          } catch (error) {
            console.error('Error signing in with email link', error);
            alert('Error signing in with email link.');
          }
        }
      }
    };

    completeSignIn();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleToggle = (collapsed) => {
    setIsInfoCollapsed(collapsed);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Header />
      <InfoSection onToggle={handleToggle} />
      {user ? <Chat isInfoCollapsed={isInfoCollapsed} user={user} /> : <Login />}
    </div>
  );
}

export default App;
