import React, { useState } from 'react';
import './InfoSection.css';

const InfoSection = ({ onToggle }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    onToggle(!isCollapsed);
  };

  return (
    <div className="info-section">
      <button onClick={toggleCollapse} className="collapse-button">
        {isCollapsed ? 'Show Info' : 'Hide Info'}
      </button>
      <div className={`info-text ${isCollapsed ? 'collapsed' : 'expanded'}`}>
      <h3>
            Welcome to GLM AI Tools! 
          </h3>
          <p>
          This is an AI chat tool to help you answer questions and brainstorm solutions to your law firm management challenges. Using other AI chat tools will give you generic answers. This chat tool is different. We've trained this on our GLM content containing decades of wisdom from Ben and Brian.
          </p>
          <p>
            This is a beta version and we will continue to improve this over time. Please reach out with any feedback. 
          </p>
      </div>
    </div>
  );
};

export default InfoSection;
