import React, { useState } from 'react';
import './Header.css';
import logo from './logo.png'; 

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <a href="https://www.greatlegalmarketing.com" className="logo-image">
        <img src={logo} alt="Logo" />
      </a>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        <a href="https://www.greatlegalmarketing.com" target="_blank" rel="noopener noreferrer">Great Legal Marketing</a>
        <a href="https://www.glmsummit.com" target="_blank" rel="noopener noreferrer">The GLM Summit</a>
        <a href="https://www.buzzsprout.com/2053620" target="_blank" rel="noopener noreferrer">Life Beyond the Briefs</a>
        <a href="https://www.buzzsprout.com/2044251" target="_blank" rel="noopener noreferrer">Renegade Lawyer Podcast</a>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
    </header>
  );
};

export default Header;
