// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Center the container horizontally and vertically */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .login-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-container input {
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 300px;
  }
  
  .login-container button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-container button:hover {
    background-color: #0056b3;
  }
  
  .login-container p {
    color: #666;
    margin-top: 10px;
    font-size: 14px;
    width: 80%;
  }
  
  .login-container p:last-of-type {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA,qDAAqD;AACrD;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,WAAW;IACX,iBAAiB;EACnB","sourcesContent":["/* Center the container horizontally and vertically */\n.login-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    text-align: center;\n    background-color: #f9f9f9;\n    font-family: Arial, sans-serif;\n  }\n  \n  .login-container h2 {\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .login-container form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .login-container input {\n    padding: 10px 15px;\n    margin-bottom: 15px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 16px;\n    width: 300px;\n  }\n  \n  .login-container button {\n    padding: 10px 20px;\n    font-size: 16px;\n    color: white;\n    background-color: #007bff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .login-container button:hover {\n    background-color: #0056b3;\n  }\n  \n  .login-container p {\n    color: #666;\n    margin-top: 10px;\n    font-size: 14px;\n    width: 80%;\n  }\n  \n  .login-container p:last-of-type {\n    font-size: 16px;\n    color: #333;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
