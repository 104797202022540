// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  background-color: transparent;
}

.logo-image {
  height: 40px;
}

.logo-image img {
  height: 100%;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: #2e2e2e;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease, border-bottom 0.2s ease;
  margin: 5px 0;
  padding-bottom: 5px; /* Add some space for the underline */
  border-bottom: 2px solid transparent; /* Transparent border by default */
}

.nav-links a:hover {
  color: #000000;
  margin-bottom: 2px;
  border-bottom: 2px solid #005bbb;
}

.hamburger {
  display: none;
  font-size: 24px; /* Increased font size for better visibility */
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #ffffff;
    position: absolute;
    top: 60px;
    right: 20px;
    width: 200px;
    border: 1px solid #444;
    padding: 10px;
  }

  .nav-links.open {
    display: flex;
  }

  .hamburger {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,8BAA8B;EAC9B,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,oDAAoD;EACpD,aAAa;EACb,mBAAmB,EAAE,qCAAqC;EAC1D,oCAAoC,EAAE,kCAAkC;AAC1E;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,eAAe,EAAE,8CAA8C;EAC/D,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 20px;\n  font-family: Arial, sans-serif;\n  background-color: transparent;\n}\n\n.logo-image {\n  height: 40px;\n}\n\n.logo-image img {\n  height: 100%;\n}\n\n.nav-links {\n  display: flex;\n  gap: 20px;\n}\n\n.nav-links a {\n  color: #2e2e2e;\n  text-decoration: none;\n  font-size: 14px;\n  transition: color 0.2s ease, border-bottom 0.2s ease;\n  margin: 5px 0;\n  padding-bottom: 5px; /* Add some space for the underline */\n  border-bottom: 2px solid transparent; /* Transparent border by default */\n}\n\n.nav-links a:hover {\n  color: #000000;\n  margin-bottom: 2px;\n  border-bottom: 2px solid #005bbb;\n}\n\n.hamburger {\n  display: none;\n  font-size: 24px; /* Increased font size for better visibility */\n  cursor: pointer;\n}\n\n@media (max-width: 768px) {\n  .nav-links {\n    display: none;\n    flex-direction: column;\n    background-color: #ffffff;\n    position: absolute;\n    top: 60px;\n    right: 20px;\n    width: 200px;\n    border: 1px solid #444;\n    padding: 10px;\n  }\n\n  .nav-links.open {\n    display: flex;\n  }\n\n  .hamburger {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
