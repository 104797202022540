import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';
import { db, auth } from './firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

function Chat({ isInfoCollapsed }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const isUserScrolling = useRef(false);

  const handleSend = async () => {
    if (input.trim() === '') return;

    const user = auth.currentUser; // Get the current user
    const userMessage = { text: input, type: 'user', email: user ? user.email : 'anonymous', timestamp: serverTimestamp() };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Log the message to Firestore
    try {
      await addDoc(collection(db, 'messages'), userMessage);
    } catch (error) {
      console.error('Error adding document to Firestore: ', error);
    }

    // Clear the input box immediately after submission
    setInput('');
    setIsStreaming(true);

    // Add placeholder bot message with ellipsis
    const botPlaceholder = { text: '...', type: 'bot' };
    setMessages((prevMessages) => [...prevMessages, botPlaceholder]);

    // Indicate that we should scroll to the bottom
    setShouldScroll(true);

    // Call the API to get the bot response
    await getBotResponse(input);

    setIsStreaming(false);
  };

  const getBotResponse = async (message) => {
    const lastMessages = messages.slice(-10).map((msg) => msg.text);

    try {
      const response = await fetch('https://glm-search-test.onrender.com/stream', {
        method: 'POST',
        headers: {
          'accept': 'text/event-stream',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          input: {
            original_query: message,
            conversation_history: lastMessages
          }
        })
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let botResponse = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        console.log('Raw chunk:', JSON.stringify(chunk)); // Log the raw chunk for inspection

        let text = chunk;
        if (text.startsWith('"') && text.endsWith('"')) {
          text = text.slice(1, -1);
        }

        // Process the text for formatting
        text = processText(text);

        console.log('Processed text:', JSON.stringify(text)); // Log processed text for inspection

        botResponse += text;

        // Update the bot message bubble with the actual response
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1] = { text: botResponse, type: 'bot' };
          return newMessages;
        });
      }
    } catch (error) {
      console.error('Error fetching bot response: ', error);
    }
  };

  const processText = (text) => {
    // Replace "\\n\\n" with <br><br> for newlines
    text = text.replace(/\\n\\n/g, '<br><br>');

    // Replace markdown style bold with HTML <strong> tags
    // text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace "\n" with <br> for newlines
    text = text.replace(/\\n/g, '<br>');

    // Add spaces after periods and commas if missing
    text = text.replace(/([.,])([^\s])/g, '$1 $2');

    return text;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
      setShouldScroll(true); // Ensure scrolling after sending
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current && shouldScroll) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      setShouldScroll(false); // Reset the scroll indicator
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [input]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, shouldScroll]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      isUserScrolling.current = scrollTop + clientHeight < scrollHeight;
    };

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className={`chat-container ${isInfoCollapsed ? 'info-collapsed' : 'info-expanded'}`} ref={chatContainerRef}>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-bubble ${msg.type === 'user' ? 'user-bubble' : 'bot-bubble'}`}
            dangerouslySetInnerHTML={{ __html: msg.text }}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input-container">
        <textarea
          ref={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          className="chat-input"
          placeholder="Ask your law firm management question here..."
          rows={1}
          disabled={isStreaming}
          style={{ overflowY: input.split('\n').length > 3 ? 'auto' : 'hidden' }}
        />
        <button onClick={handleSend} className="chat-send-button" disabled={isStreaming}>
          &#9654;
        </button>
      </div>
    </div>
  );
}

export default Chat;
