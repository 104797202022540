// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDiLCvevyDL3-TP_K8llAtX914WUX1oKfw",
    authDomain: "glm-ai-tools-auth.firebaseapp.com",
    projectId: "glm-ai-tools-auth",
    storageBucket: "glm-ai-tools-auth.appspot.com",
    messagingSenderId: "533553378696",
    appId: "1:533553378696:web:0900406ca68d0aa431763d",
    measurementId: "G-8E555R3E8B"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  
  export { db, auth };